import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import AuthGuardBS from "./components/guards/AuthGuardBS";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import SignUpStep2 from "./pages/auth/SignUp_Step2"
import SignUpStep3 from "./pages/auth/SignUp_Step3"
import ResetPassword from "./pages/auth/ResetPassword";
import ResetPasswordStep2 from "./pages/auth/ResetPasswordStep2"
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Protected routes
import MinhaConta_MeusDocumentos from "./pages/minha-conta/meus-documentos/index";
import MinhaConta_MeusDados from "./pages/minha-conta/dados-conta/index";
import MinhaConta_Tarifas from "./pages/minha-conta/tarifas/index"

// Meus clientes
import MeusClientes_Listagem from "./pages/meus-clientes/lista/index";
import MeusClientes_Show from "./pages/meus-clientes/show/index";


// Cobranças
import Cobranca_Lista from "./pages/Cobrancas/lista";
import Cobranca_Show from "./pages/Cobrancas/show";


import CobrancaCarne_Lista from "./pages/Carne/lista"
import Clientes_Inadimplentes from "./pages/Cobrancas/inadimplentes/lista"

import SignatureList from "./pages/signature/lista"
import SignatureConfig from "./pages/signature/config/index"

// Payee Acccounts
import PayeeAccount_List from "./pages/payee-account/lista"
import AccountBankTransfer from "./pages/account-bank/lista"


// Automacao

import AutomacaoEmail from "./pages/automacao/email/lista"
import AutomacaoSMS from "./pages/automacao/sms/lista"
import AutomacaoVoice from "./pages/automacao/voice/lista"
import AutomacaoWhatsapp from "./pages/automacao/whatsapp/lista"
import AutomacaoEnviadas from "./pages/automacao/enviadas/index"


// Portal do Cliente
import PortalClienteExtAtribuirAcesso from "./pages/Portal-do-Cliente/atribuir-acesso/index"
import PortalClienteExtConfiguracao from "./pages/Portal-do-Cliente/configuracao/index"
import PortalClienteExtClientesAssociados from "./pages/Portal-do-Cliente/clientes-associados/index"
import SignInSecret from "./pages/auth/SignInSecret";
import RechargePhone from "./pages/recharge-phone/lista";
import IntegracaoAccess from "./pages/Integracao/IntegracaoAccess";
import ScannerBarCodeBoleto from "./pages/Cobrancas/scanner-barcode";
import AuthGuardScreenLock from "./components/guards/AuthGuardScreenLock";
import ValidateToken from "./pages/auth/ValidateToken";
import PayPaymentAccount from "./pages/payment-accounts/pagar";
import ExtratoComponent from "./pages/ExtratoBancario";
import SignatureView from "./pages/signature/view";

// Dashboard components
const Default = async(() => import("./pages/dashboard"));

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
      {
        path: "sign-in/",
        element: <SignIn />,
      },
      
      {
        path: "logout",
        element: <SignIn />,
      },
      {
        path: "auth/secret/:access_token/:business_account_id",
        element: <SignInSecret />,
      },
      {
        path: "login/",
        element: <SignIn />,
      },
      
      {
        path: "sign-in/:resetedPassword",
        element: <SignIn />,
      },

      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "reset-password/:email/",
        element: <ResetPasswordStep2 />,
      },
      {
        path: "reset-password/:email/:token",
        element: <ResetPasswordStep2 />,
      },
      
      {
        path: "criar-conta",
        element: <SignUp />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
     
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "/sign-up",
    element: <AuthLayout />,
    children: [
      {
        path: "step2",
        element: (
          <AuthGuard>
            <SignUpStep2 />
          </AuthGuard>
        ),
      },
      {
        path: "step3",
        element: (
          <AuthGuardBS>
            <SignUpStep3 />
          </AuthGuardBS>
        ),
      }
    ],
  },
  {
    path: "/app/access",
    element: <AuthLayout />,
    children: [
      {
        path: "check",
        element: (
          <AuthGuardScreenLock>
            <ValidateToken />
          </AuthGuardScreenLock>
        ),
      }
    ],
  },

  {
    path: "app",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },

  {
    path: "meus-clientes",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      {
        path: "",
        element: <MeusClientes_Listagem />,
      },
      {
        path: "visualizar/:id",
        element: <MeusClientes_Show />,
      },
      {
        path: "meus-dados",
        element: <MinhaConta_MeusDados />,
      },
      {
        path: "inadimplentes",
        element: <Clientes_Inadimplentes />,
      },
    ],
  },

  {
    path: "minha-conta",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      {
        path: "documentos",
        element: <MinhaConta_MeusDocumentos />,
      },
      {
        path: "meus-dados",
        element: <MinhaConta_MeusDados />,
      },
      {
        path: "detalhes",
        element: <MinhaConta_Tarifas/>,
      },
    ],
  },

  {
    path: "cobrancas",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      {
        path: "",
        element: <Cobranca_Lista />,
      },
      {
        path: "carnes",
        element: <CobrancaCarne_Lista />,
      },
      {
        path: "show/:id",
        element: <Cobranca_Show />,
      },
      {
        path: "scanner/boleto",
        element: <ScannerBarCodeBoleto />,
      },
    ],
  },

  {
    path: "assinaturas",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      {
        path: "",
        element: <SignatureList />,
      },
      {
        path: "lista",
        element: <SignatureList />,
      },
      {
        path: "show/:id",
        element: <SignatureView />,
      },
      
      {
        path: "config",
        element: <SignatureConfig />,
      },
    ],
  },

  
  {
    path: "app/pagamento-de-contas",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      
      {
        path: "",
        element: <PayPaymentAccount />,
      },
      {
        path: ":id",
        element: <PayPaymentAccount />,
      }
    ],
  },

  
  {
    path: "app/extrato",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      
      {
        path: "",
        element: <ExtratoComponent />,
      }
    ],
  },
  
  {
    path: "transferencias",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      
      {
        path: "",
        element: <AccountBankTransfer />,
      },
      {
        path: "contas-bancarias",
        element: <PayeeAccount_List />,
      }
    ],
  },
  {
    path: "recarga",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      
      {
        path: "",
        element: <RechargePhone />,
      }
    ],
  },
  {
    path: "automacao",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      
      {
        path: "email",
        element: <AutomacaoEmail />,
      },
      {
        path: "sms",
        element: <AutomacaoSMS />,
      },
      {
        path: "robo-voz",
        element: <AutomacaoVoice />,
      },
      {
        path: "whatsapp",
        element: <AutomacaoWhatsapp />,
      },
      {
        path: "enviadas",
        element: <AutomacaoEnviadas />,
      }
    ],
  },
  {
    path: "portal-do-cliente",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      {
        path: "atribuir-acesso",
        element: <PortalClienteExtAtribuirAcesso />,
      },
      {
        path: "configuracao",
        element: <PortalClienteExtConfiguracao />,
      },
      {
        path: "clientes-associados",
        element: <PortalClienteExtClientesAssociados/>,
      },
    ],
  },
  {
    path: "integracao",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      {
        path: "acesso",
        element: <IntegracaoAccess />,
      }
    ],
  },

  
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
