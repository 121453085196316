import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import AddNewAutomationSMS from "../add/index"

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes() {
let { getApi,postApi ,deleteApi} = useAuth();
const [listAutomationRoutineSMS, setListAutomationRoutineSMS] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);





const [page, setPage] = React.useState(0);
const [limitPage, setLimitPage] = React.useState(5);
const [totalRecords, setTotalRecords] = React.useState(0);

const [openEditClient, setOpenEditClient] = React.useState(false);
const [selectedEditValue, setSelectedEditValue] = React.useState([]);
const handleClickOpenEditClient = (selectedEditValue) => {
  setOpenEditClient(true);
  setSelectedEditValue(selectedEditValue);
};

const handleCloseEditClient = (value) => {
  setOpenEditClient(false);
  setSelectedEditValue(value);
};

const navigate = useNavigate();

const columns = [
  {
    field: "created_at",
    headerName: "Dt. Criação",
    width: 200,
    editable: false, renderCell: (params) => {
      
     
      return <div className="rowitem">
         {  new Date(params.row.created_at).toLocaleDateString('pt-br')+
      " "+
      new Date(params.row.created_at).toLocaleTimeString('pt-br')
      }
      </div>;
   }

  },
  {
    field: "condition",
    headerName: "Condição",
    width: 600,
    editable: false,
    renderCell: (params) => {
     if(params.row.notification_type.id==1){
        return <div className="rowitem">A notificação de cobrança será enviada no momento em que a cobrança for criada</div>;
      }
      if(params.row.notification_type.id==2){
      return <div className="rowitem">A notificação de cobrança será enviada {params.row.condition.overdue} dias após o vencimento.</div>;
      }
   
      if(params.row.notification_type.id==3){
       return <div className="rowitem">A notificação de cobrança será enviada {params.row.condition.beforedue} dias antes do vencimento.</div>;
      }

      if(params.row.notification_type.id==4){
         return <div className="rowitem">A notificação de cobrança será enviada no mesmo dia do vencimento.</div>;
      }

      if(params.row.notification_type.id==5){
         return <div className="rowitem">Será enviada uma notificação com a confirmação do pagamento.</div>;
      }

      if(params.row.notification_type.id==6){
         return <div className="rowitem">Será enviada uma notificação com informativo de cobrança cancelada.</div>;
      }
 
    }
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    editable: false, renderCell: (params) => {  
      return <div className="rowitem">{params.row.activated?"Ativo":"Desativado"}</div>;
   }

  },

  {
    field: "action",
    headerName: "Ação",
    sortable: false,
    width: 300,
    renderCell: (params: GridRenderCellParams<Date>) => (
      <React.Fragment>
        <Button
          variant="contained"
          size="small"
          color="error"
          style={{ marginLeft: 16 }}
          onClick={e=>deleteAutomation(params.row.id)}
          tabIndex={params.hasFocus ? 0 : -1} 
          disabled={loadingList}>Excluir
        </Button>
      </React.Fragment>
    
        
    ),
  },
];



const [query_type, setQueryType] = React.useState("fullname");
const [query, setQuery] = React.useState(null);
const [selectionModel, setSelectionModel] = useState([]);

useEffect(() => {

  LoadAutomationSMS();
},[]);

const LoadAutomationSMS = async (page_arg=null, limit_arg=null) => {
    
  setListAutomationRoutineSMS([]);
  setLoadingList(true);
  let response = await getApi("/automation/sms/list",{
    params:{
      limit:limit_arg!=null?limit_arg:limitPage,
      page:page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page),
      query_type:query_type,
      queryString:query
    }
  });
  
  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  setListAutomationRoutineSMS(response.lista.map(x=> x.routine));
  setLoadingList(false);

};


const deleteAutomation = async (id) => {
  if (!window.confirm("Você realmente deseja excluir esta automação?")) {
    return ;
  }
  

  setLoadingList(true);
  let response = await deleteApi("/automation/sms/"+id,{} );
  if(response.success==true){
    alert("Automação excluida com sucesso.");
    
  setLoadingList(true);
    LoadAutomationSMS();
  }else{
    
  setLoadingList(false);
  }

  
};


const setRow=(row)=>{
  setSelectionModel(row);
}



  return (
    <Card mb={0}>
      <CardContent pb={1}>
  
       
      </CardContent>
                 
      <Paper>
        <div style={{ height: (limitPage==5?400:400+(limitPage*15)), width: "100%" }}>
     

          <DataGrid
            rowsPerPageOptions={[3, 5, 10,50,100]}
            rows={listAutomationRoutineSMS}
            columns={columns}
            pageSize={limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={e  => {LoadAutomationSMS(null, e)  } }
            paginationMode={'server'}
            onPageChange={e  => {LoadAutomationSMS(e, null)  } }
            loading={loadingList}
            disableMultipleSelection
            onSelectionModelChange={(selection) => {
             
              setRow(selection);
            }}
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Registros por página'
              }
            }}

            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Automações de sms não encontrada
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum resultado encontrado.
                </Stack>
              )
            }}
            
          />
    
        </div>
              
    




      </Paper>

      
    </Card>

    
  );
}

  function PayeeAccountList() {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState([1]);
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (value) => {
      setOpen(false);
      setSelectedValue(value);
    };





  return (
    <React.Fragment>
      <Helmet title="Automações de SMS" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h4" gutterBottom display="inline">
        Automações via SMS
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/transferencias">
          Automação
        </Link>
        <Typography>Automação via SMS</Typography>
      </Breadcrumbs>
      </Grid>
      <Grid item>
          <div>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              <AddIcon />
              Criar nova automação
            </Button>
          </div>
        </Grid>
      
        </Grid>


  
      <DataGridClientes />






      <AddNewAutomationSMS
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
            key={selectedValue}
          />

       
       
    </React.Fragment>
  );
}

export default PayeeAccountList;
