import {
  Briefcase,
  Grid,
  DollarSign,
  PhoneCall,
  Folder,
  Cloud,
  Edit,
  Home,
  UserCheck,
  Server,
  Layers,
  Map,
  Monitor,
  RotateCcw,
  MessageCircle,
} from "react-feather";
import { FiHeart,FiPhoneCall,FiKey, FiPower, FiTool, FiShoppingCart, FiBookOpen, FiFolder, FiShield, FiPlayCircle, FiXCircle } from "react-icons/fi";
const DashBoardItensFunc = (is_adm,level_id) => {



  const pagesSection = [
    {
      href: "/app",
      icon: Home,
      title: "DashBoard"
    },
    {
      href: "#",
      icon: Edit,
      title: "Meus Clientes",
      children: [
        {
          href: "#addNewClient",
          title: "Cadastrar um novo",
        },
        {
          href: "/meus-clientes",
          title: "Lista de Clientes",
        },
        {
          href: "/meus-clientes/inadimplentes",
          title: "Inadimplentes",
        }
      ],
    },
    {
      href: "#",
      icon: Briefcase,
      title: "Cobranças",
      children: 
      [
        {
          href: "/cobrancas/#",
          title: "Todas cobranças",
        },
        {
          href: "/cobrancas/carnes",
          title: "Carnês de boletos",
        },
        {
          href: "#",
          title: "Assinatura",
          children: 
          [
            {
              href: "/assinaturas/#",
              title: "Ver todos",
            }
            
            
          ]
          
        },
      ]
      
    }
    
  
    ];
    const MeuDinheiroSection = [
    {
      href: "#",
      icon: DollarSign,
      title: "Transferências",
      children: [
        {
          href: "/transferencias/contas-bancarias",
          title: "Contas Bancárias",
        },
        {
          href: "/transferencias",
          title: "Extrato de Saques",
        }
      ],
    },
    {
      href: "#",
      icon: FiFolder,
      title: "Comodidades",
      children: [
        {
          href: "/recarga",
          icon: FiPhoneCall,
          title: "Recarregue seu celular (cashback)"
        },
        {
          href: "/app/pagamento-de-contas",
          icon: Layers,
          title: "Pagamento de contas"
        },
      ],
    },
    {
      href: "/app/extrato",
      icon: FiBookOpen,
      title: "Extrato da conta"
    }
  ];
  
  
  const configContaSection = [
    {
      href: "#",
      icon: FiShield,
      title: "Minha Conta",
      children: [
        {
          href: "/minha-conta/meus-dados",
          title: "Dados da conta",
        },
        {
          href: "/minha-conta/detalhes",
          title: "Detalhes da Conta",
        },
        {
          href: "/minha-conta/documentos",
          title: "Meus Documentos",
        }
      ],
    }
  ];
  
  
  const FerramentasModulosSection = [
    {
      href: "/components",
      icon: PhoneCall,
      title: "Automação",
      children: [
        {
          href: "/automacao/email",
          title: "Automação via E-mail",
        },
        {
          href: "/automacao/sms",
          title: "Automação via SMS",
        },
        {
          href: "/automacao/whatsapp",
          title: "Automação via Whatsapp",
        },
        {
          href: "/automacao/robo-voz",
          title: "Robô de voz",
        },
        {
          href: "/automacao/enviadas",
          title: "Automações enviadas",
        }
      ],
    },
    {
      href: "/components",
      icon: Cloud,
      title: "Portal do cliente",
      children: [
        {
          href: "/portal-do-cliente/atribuir-acesso",
          title: "Atribuir Acesso",
        },
        {
          href: "/portal-do-cliente/clientes-associados",
          title: "Clientes associados",
        },
        {
          href: "/portal-do-cliente/configuracao",
          title: "Configuração",
        }
      ],
    },
     {
       href: "#",
       icon: FiKey,
       title: "Integração API",
       children: [
         {
           href: "/integracao/acesso",
           title: "Chaves de Acesso",
         }
       ],
     },
  ];
  
  
  const AtendimentoAoClienteSection = [
    {
      href: "/components",
      icon: Grid,
      title: "Solicitações",
      children: [
        {
          href: "/components/alerts",
          title: "Abrir uma nova",
        },
        {
          href: "/components/accordion",
          title: "Ver solicitações",
        }
      ],
    },
    {
      href: "/components",
      icon: Grid,
      title: "Meu Consultor"
    }
  ];
  
  
  
  let navItens=[];

  if(
    ['995b8aad-97c1-492c-aa03-a45cf8a1645a','26632']
      .includes(localStorage.getItem('@CacheBank_BusinessAccount'))
    ){
      let indexCobranca=pagesSection.findIndex(x=> x.title=='Cobranças');
      pagesSection[indexCobranca]['children'].push({
        href: "/cobrancas/scanner/boleto",
        title: "Pesquisar BarCode",
      });
    }
  

  if(is_adm==true){
    let indexCobranca=pagesSection.findIndex(x=> x.title=='Cobranças');
    console.log(pagesSection[indexCobranca]["children"]);
    let indexAssinatura=pagesSection[indexCobranca]["children"].
      findIndex(x=> x.title=='Assinatura');

      pagesSection[indexCobranca]["children"][indexAssinatura]['children'].push({
        href: "/assinaturas/config",
        title: "Configuração",
      });

    navItens.push({
      title: "DashBoard",
        pages: pagesSection,
     }
    );

    navItens.push({
      title: "Meu Dinheiro",
      pages: MeuDinheiroSection,
    }
    );

    navItens.push({
      title: "Configuração da Conta",
      pages: configContaSection,
    }
    );

    navItens.push( {
      title: "Módulos e Ferramentas",
      pages: FerramentasModulosSection,
    }
    );

   


  }else{

    navItens.push({
      title: "DashBoard",
        pages: pagesSection,
     }
    );

    if(String(level_id).includes('_commercial_')){
      
    }

    if(String(level_id).includes('_finance_')){
      navItens.push(
        {
          title: "Meu Dinheiro",
          pages: MeuDinheiroSection,
        }
      );
    }


  }
  

   

  return navItens;
}

export default DashBoardItensFunc;
