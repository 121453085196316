import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes() {
let { getApi,postApi ,deleteApi} = useAuth();
const [listPayeersAccounts, setListPayeersAccounts] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);


const [noRowsMessage, setNoRowsMessage] = React.useState('Nenhum resultado encontrado.');



const [page, setPage] = React.useState(0);
const [limitPage, setLimitPage] = React.useState(15);
const [totalRecords, setTotalRecords] = React.useState(0);

const [openEditClient, setOpenEditClient] = React.useState(false);
const [selectedEditValue, setSelectedEditValue] = React.useState([]);
const handleClickOpenEditClient = (selectedEditValue) => {
  setOpenEditClient(true);
  setSelectedEditValue(selectedEditValue);
};

const handleCloseEditClient = (value) => {
  setOpenEditClient(false);
  setSelectedEditValue(value);
};

const navigate = useNavigate();

const columns = [
  { field: "id", headerName: "ID", width: 150,  hide: true },
  {
    field: "created_at",
    headerName: "Data",
    width: 150,
    editable: false,
    renderCell: (params) => {
      let DateC=new Date(params.row.created_at);
      return <div className="rowitem"> {
        DateC.toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})
        +" "+DateC.getHours()+":"+DateC.getMinutes()+":"+DateC.getSeconds()
        
        }</div>;
    }
  },
  {
    field: "name2",
    headerName: "Nome",
    width: 200,
    editable: false,
    renderCell: (params) => {
      let payee=params.row.payee_account;

      return <div className="rowitem">{payee.name}</div>;
    }
  },
  {
    field: "tax_identifier",
    headerName: "CPF/CNPJ",
    width: 200,
    editable: false,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.payee_account.tax_identifier}</div>;
    }
  },
  {
    field: "amount",
    headerName: "Valor",
    width: 120,
    editable: false,
    renderCell: (params) => {
      return <div className="rowitem">{parseFloat(params.row.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>;
    }
  },
  {
    field: "fee",
    headerName: "Taxa",
    width: 100,
    editable: false,
    renderCell: (params) => {
      if(parseFloat(params.row.fee)==0){

        return <div className="rowitem">Grátis</div>;
      }else{
        return <div className="rowitem">{parseFloat(params.row.fee).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>;
      }
    }
  },
  {
    field: "transfer_type_name",
    headerName: "Via",
    width: 100,
    editable: false,
    renderCell: (params) => {
        return <div className="rowitem">{params.row.transfer_type_name}</div>;
      
    }
  },
  {
    field: "transfer_status_name",
    headerName: "Status",
    width: 100,
    editable: false,
    renderCell: (params) => {
        return <div className="rowitem">{params.row.transfer_status_name}</div>;
      
    }
  },
  {
    field: "agency",
    headerName: "Agência",
    width: 100,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.payee_account.agency}</div>;
    }
  },
  {
    field: "account_number",
    headerName: "Numero da Conta",
    width: 150,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.payee_account.account_number}</div>;
    }
  },
  {
    field: "account_type_name",
    headerName: "Tipo Conta",
    width: 150,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.payee_account.account_type_name}</div>;
    }
  },
  {
  field: "ltbank",
  headerName: "Instituição",
  sortable: false,
  width: 300,
  renderCell: (params) => {
    return <div className="rowitem">{params.row.payee_account.bank.name}</div>;
  }
  },
  {
    field: "scheduled_at",
    headerName: "Agendamento",
    width: 150,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      if(params.row.scheduled_at){
        let DateC=new Date(params.row.scheduled_at);
        return <div className="rowitem"> {
          DateC.toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})
          +" "+DateC.getHours()+":"+DateC.getMinutes()+":"+DateC.getSeconds()
          
          }</div>;
      }else{
        return <div className="rowitem">-</div>;
      }
      
    }
  },
];



const [query_type, setQueryType] = React.useState("fullname");
const [query, setQuery] = React.useState(null);
const { balance_available } = useAuth();

useEffect(() => {

  LoadMyPayeeBank();
},[]);

const LoadMyPayeeBank = async (page_arg=null, limit_arg=null) => {
    
  setListPayeersAccounts([]);
  setLoadingList(true);
  let response = await getApi("/payee_account/withdraw/list",{
   
      limit:limit_arg!=null?limit_arg:limitPage,
      page:page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page),
      query_type:query_type,
      queryString:query
  });

  if(response.message){
    if(response.message=='This action is unauthorized.'){
      setNoRowsMessage("Acesso não autorizado");
      setLoadingList(false);
      return ;
    }
  }
  
  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  setListPayeersAccounts(response.lista.map(x=> x.accountBankTransfer));
  setLoadingList(false);

};







  return (
    <Card mb={0}>
      <CardContent pb={1}>
  
       
      </CardContent>
                 
      <Paper>
        <div style={{ height: (limitPage==5?380:300+(limitPage*15)), width: "100%" }}>
     

          <DataGrid
            rowsPerPageOptions={[5,10, 15,50,100]}
            rows={listPayeersAccounts}
            columns={columns}
            pageSize={limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={e  => {LoadMyPayeeBank(null, e)  } }
            paginationMode={'server'}
            onPageChange={e  => {LoadMyPayeeBank(e, null)  } }
            loading={loadingList}
            disableMultipleSelection
            
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Registros por página'
              }
            }}

            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                 {noRowsMessage}
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {noRowsMessage}
                </Stack>
              )
            }}
            
          />
    
        </div>
              
    




      </Paper>

      
    </Card>

    
  );
}

  function PayeeAccountList() {
    
const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState([]);
    const handleClickOpen = () => {
      navigate("/transferencias/contas-bancarias");
    };

  




  return (
    <React.Fragment>
      <Helmet title="Transferencias" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h4" gutterBottom display="inline">
       Transferências
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/transferencias">
          Transferências
        </Link>
        <Typography>Listagem</Typography>
      </Breadcrumbs>
      </Grid>
      <Grid item>
          <div>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              <AddIcon />
              Realizar uma nova transferências
            </Button>
          </div>
        </Grid>
      
        </Grid>


  
      <DataGridClientes />







       
       
    </React.Fragment>
  );
}

export default PayeeAccountList;
