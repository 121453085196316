import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
import CreateSignature from "../../signature/createsignature/index"
import { LoadingButton } from "@mui/lab";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes() {
let { getApi,postApi } = useAuth();
const [listCobr, setListCobr] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);


const [signatureStatus, setSignatureStatus] = useState(-1);



const [page, setPage] = useState(0);
const [limitPage, setLimitPage] = useState(5);
const [totalRecords, setTotalRecords] = useState(0);

const [openEditClient, setOpenEditClient] = useState(false);
const [selectedEditValue, setSelectedEditValue] = useState([]);


const [arrayResult, setArrayResult] = React.useState([]);


const handleClickOpenEditClient = (selectedEditValue) => {
  setOpenEditClient(true);
  setSelectedEditValue(selectedEditValue);
};

const handleCloseEditClient = (value) => {
  setOpenEditClient(false);
  setSelectedEditValue(value);
};

const navigate = useNavigate();

const ColorButtonTransaction =(status)=>{
  let color="primary";
  if(status==0){
    color="boot_warning"
  }else if(status==1){
    color="boot_success"
  }else if(status==2){
    color="boot_info"
  }else if(status==3){
    color="boot_secondary"
  }else if(status==4){
    color="boot_dark"
  }else if(status==5){
    color="boot_danger"
  }else if(status==6){
    color="boot_dark"
  }else if(status==7){
    color="boot_success"
  }else if(status==8){
    color="boot_success"
  }else if(status==9){
    color="boot_success"
  }else if(status==10){
    color="boot_secondary"
  }

  return color;
}

const columns = [
  {
    field: "fullname",
    headerName: "Nome Completo",
    sortable: false,
    width: 150,
    editable: false,
    renderCell: (params)  => (
      <React.Fragment>
       <Link
       href={"/assinaturas/show/"+params.row.id}
          onClick={e=> handleClickOpenEditClient(params.row)} >
         {params.row.costumer.fullname}
        </Link>
    </React.Fragment>

    ) },

  {
    field: "tax_identifier",
    headerName: "CPF/CNPJ",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.costumer.tax_identifier}</div>;
    }
  },
  {
    field: "reference",
    headerName: "Referência",
    sortable: false,
    width: 120,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 120,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.status.name}</div>;
    }
  },
  {
    field: "first_due_date",
    headerName: "1º Vencimento",
    sortable: false,
    width: 140,
    renderCell: (params) => {
      let DateC=new Date(params.row.first_due_date);
      return <div className="rowitem"> {
        DateC.toLocaleDateString('pt-br')
        }</div>;
    }
  },
  {
    field: "amount",
    headerName: "V. Parcela",
    sortable: false,
    width: 120,
    renderCell: (params) => {
      return <div className="rowitem">{parseFloat(params.row.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>;
    }
  },
  {
    field: "Parcelastotais",
    headerName: "Parcelas",
    sortable: false,
    width: 90,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.installments==-1?"Indeterminado":params.row.installments}</div>;
    }
  },
  {
    field: "ParcelasPendente",
    headerName: "Em aberto",
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.installments_pending}</div>;
    }
  },
  {
    field: "ParcelasAtrasadas",
    headerName: "Atrasadas",
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.installments_overdue}</div>;
    }
  },
  {
    field: "action",
    headerName: "Ação",
    sortable: false,
    width: 300,
    renderCell: (params: GridRenderCellParams<Date>)  => (
      <React.Fragment>
        <Button
          variant="contained"
          size="small"
          color={"boot_primary"}
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={e => navigate("/assinaturas/show/"+params.row.id)}>
          Visualizar
        </Button>
      </React.Fragment>
    
        
    ),
  },
];



const [statusList, setStatusList] = useState([]);

const [search_Status, setSearchStatus] = useState(-1);
const [searchText, setSearchText] = useState(null);
const [query_type, setQueryType] = useState("fullname");
const [query_filterDateType, setQuery_filterDateType] = useState(1);

const [query_filterDateStart, setQuery_filterDateStart] = useState(null);
const [query_filterDateEnd, setQuery_filterDateEnd] = useState(null);


function LoadQueryParams(limit_arg,page_arg){

  let invoiceTmp={};
  
  invoiceTmp["limit"]=limit_arg;
  invoiceTmp["page"]=page_arg;
  invoiceTmp["minimal_data"]=true;

  invoiceTmp["queryString"]={
    "signature":{ },
    "status":search_Status
  };

  invoiceTmp["queryString"]["signature"]["status"]=signatureStatus;



   // Filtro de Data
   invoiceTmp["queryString"]["signature"]["filterdate"]=query_filterDateType;
   invoiceTmp["queryString"]["signature"]["filterdate"]=query_filterDateType;
    invoiceTmp["queryString"]["signature"]["filterdatevalue"]={
     "start":query_filterDateStart?new Date(query_filterDateStart).toLocaleString("en", {timeZone: "America/Sao_Paulo"}):null,
     "end": query_filterDateEnd?new Date(query_filterDateEnd).toLocaleString("en", {timeZone: "America/Sao_Paulo"}):null
   };

   // Filtro de usuario
   if(["fullname", "tax_identifier", "email", "phone"].includes(query_type)){
     
    invoiceTmp["queryString"]["costumer"]={};
     if(query_type=="fullname"){
       invoiceTmp["queryString"]["costumer"]["fullname"]=searchText;
     }else if(query_type=="tax_identifier"){
       invoiceTmp["queryString"]["costumer"]["tax_identifier"]=searchText;
     } else if(query_type=="email"){
       invoiceTmp["queryString"]["costumer"]["email"]=searchText;
     }else if(query_type=="phone"){
       invoiceTmp["queryString"]["costumer"]["phone"]=searchText;
     }
   }


   // Invoice Filter
   if(["id", "reference"].includes(query_type)){
     if(query_type=="id"){
       invoiceTmp["queryString"]["signature"]["id"]=searchText;
     }else if(query_type=="reference"){
       invoiceTmp["queryString"]["signature"]["reference"]=searchText;
     }
   }


  return invoiceTmp;

}

useEffect(() => {

  LoadStatusList();
  LoadList();
},[]);

const LoadStatusList = async (page_arg=null, limit_arg=null) => {
    
  let response = await getApi("/invoice/status/list",{ });
  setStatusList(response)
};



const LoadList = async (page_arg=null, limit_arg=null) => {
    

  setListCobr([]);
  setLoadingList(true);
  let Arrayp=LoadQueryParams(limit_arg!=null?limit_arg:limitPage,page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page));
  let response = await postApi("/invoice/signature/list", Arrayp);
  
  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  setListCobr(response.lista.map(x=> x.signature));
  setLoadingList(false);

};

useEffect(() => {
  if(loadingList==false){
    LoadList();
  }
  
}, [signatureStatus]);


  return (
    <Card mb={6}>
      <CardContent pb={1}>
  
       
      </CardContent>
      <Typography variant="h6" gutterBottom>
          Procurar
        </Typography>
      <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    id="query"
                    placeholder={"Campo de busca"}
                    variant="outlined"
                    fullWidth
                    my={2}
                    type="text"
                    value={searchText}
                    onChange={e=> setSearchText(e.target.value)}
                  />
                </Grid>
                <Grid item md={4}>
                  
                <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Tipo de pesquisa</InputLabel>
                      <Select fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={"Tipo de pesquisa"}
                        value={query_type}
                        onChange={e => setQueryType(e.target.value)}>
                          <MenuItem value={"fullname"}>Nome do cliente</MenuItem>
                          <MenuItem value={"tax_identifier"}>CPF/CNPJ</MenuItem>
                          <MenuItem value={"id"}>Código da Assinatura</MenuItem>
                          <MenuItem value={"reference"}>Código de Referência</MenuItem>
                          <MenuItem value={"email"}>E-mail</MenuItem>
                          <MenuItem value={"phone"}>Telefone</MenuItem>
                        
                      </Select>
                    </FormControl>
                   
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item md={2}>
                    <FormControl fullWidth  >
                    <InputLabel id="demo-simple-select-label">{"Situação"}</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Situação"
                        value={search_Status}
                        onChange={e=> setSearchStatus(e.target.value)}
                      >
                      <MenuItem value={-1}>Geral</MenuItem>
                      <MenuItem value={1}>Pagamentos completados</MenuItem>
                      <MenuItem value={2}>Pagamentos pendentes</MenuItem>
                      <MenuItem value={3}>Pagamentos vencidos</MenuItem>
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item md={2}>
                    <FormControl fullWidth  >
                    <InputLabel id="demo-simple-select-label">{"Filtrar por data"}</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Filtrar por data"
                        value={query_filterDateType}
                        onChange={e=> setQuery_filterDateType(e.target.value)}
                      >
                      <MenuItem value={1}>Emissão da Assinatura </MenuItem>
                      <MenuItem value={2}>Aprovação das faturas </MenuItem>
                      <MenuItem value={3}>Vencimento das faturas </MenuItem>
                      <MenuItem value={4}>1º Vencimento da assinatura</MenuItem>
                      <MenuItem value={5}>Último vencimento da assinatura</MenuItem>
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item md={2}>
                  <TextField
                    id="query"
                    label={"Data Inicial"}
                    variant="outlined"
                    fullWidth
                    type="date"
                    focused={true}
                    onChange={e=> setQuery_filterDateStart(e.target.value)}
                    value={query_filterDateStart}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    id="query"
                    label={"Data Final"}
                    variant="outlined"
                    fullWidth
                    type="date"
                    focused={true}
                    onChange={e=> setQuery_filterDateEnd(e.target.value)}
                    value={query_filterDateEnd}
                  />
                </Grid>
              </Grid>
              <Button variant="contained"  color="primary" mt={3} onClick={e => LoadList(0)}>
                Pesquisar
              </Button>

              <Divider my={6} />

              <Grid container justifyContent={"center"}>
                <Grid>
                <center>
                  <InputLabel id="demo-simple-select-label">{"Status da assinatura"}</InputLabel>
                </center>

                <ToggleButtonGroup
                sx={{marginBottom:5}}
                  color="primary"
                  value={signatureStatus}
                  exclusive
                  size="small"
                  onChange={(event, value) => setSignatureStatus(value)}
                  aria-label="Platform"
                >
                  <ToggleButton value={-1}>Todos</ToggleButton>
                  <ToggleButton value={1}>Ativo</ToggleButton>
                  <ToggleButton value={2}>Suspenso</ToggleButton>
                  <ToggleButton value={3}>Cancelado</ToggleButton>
                </ToggleButtonGroup>

                </Grid>

              </Grid>
      <Paper>
        <div style={{ height: (limitPage==5?400:600), width: "100%" }}>
     

          <DataGrid
          key={listCobr?listCobr.total:null}
            rowsPerPageOptions={[5, 10, 25, 50,100]}
            rows={listCobr}
            columns={columns}
            pageSize={limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={e  => {LoadList(null, e)  } }
            paginationMode={'server'}
            onPageChange={e  => {LoadList(e, null)  } }
            loading={loadingList}  
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Registros por página'
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum resultado encontrado
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum resultado encontrado.
                </Stack>
              )
            }}
            
          />
          
        
    
        </div>
      </Paper>
    </Card>
  );
}

function DataGridPage() {
  const [open, setOpen] = React.useState(false);
const [selectedValue, setSelectedValue] = React.useState([]);

const handleClose = (value) => {
  ShowModalFunc(null)
};

let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();




  return (
    <React.Fragment>
      <Helmet title="Lista de Assinaturas" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h4" gutterBottom display="inline">
        Minhas Assinaturas
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/assinaturas">
        Assinaturas
        </Link>
        <Typography>Listagem</Typography>
      </Breadcrumbs>
      </Grid>
      <Grid item>
          <div>
          <Button variant="contained" color="primary" onClick={e=> ShowModalFunc("emitr_assinatura")}  disabled={isInitializedBS==false}>
              <AddIcon />
              Emitir Assinatura
            </Button>
            {isInitializedBS==false &&
                <LinearProgress />}
          </div>
        </Grid>
      
        </Grid>


      <Divider my={6} />
  
      <DataGridClientes />

      <CreateSignature
        selectedValue={selectedValue}
        open={showModal=="emitr_assinatura"}
        onClose={handleClose}
        key={"assinatura"+showModal}
      />

       
       
    </React.Fragment>
  );
}

export default DataGridPage;
