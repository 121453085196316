import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  Tab,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
import CreateSignature from "../../signature/createsignature/index"
import { LoadingButton } from "@mui/lab";
import SignatureConfigTriggerCreate from "./triggers/create";
import SignatureConfigNotification from "./triggers/createNotification";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);


function SignatureConfig() {

const [menuItem, setMenuItem] = React.useState(0);


let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();




  return (
    <React.Fragment>
      <Helmet title="Lista de Assinaturas" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h4" gutterBottom display="inline">
        Configuração Assinaturas
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/assinaturas">
        Assinaturas
        </Link>
        <Link component={NavLink} to="/assinaturas/config">
        Configuração
        </Link>
      </Breadcrumbs>
      </Grid>
      
        </Grid>

      <Divider my={4} />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={menuItem}  onChange={(e,v) => setMenuItem(v)} aria-label="basic tabs example">
          <Tab  label="Gatilhos Automáticos" />
        </Tabs>
      </Box>
      <CustomTabPanel value={menuItem} index={0}>
        <SignatureConfigTriggerCreate />

      </CustomTabPanel>
    

      <CustomTabPanel value={menuItem} index={2}>
        Item Three
      </CustomTabPanel>

       
       
    </React.Fragment>
  );
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default SignatureConfig;
