import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  Tab,
  Checkbox,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
import CreateSignature from "../../createsignature/index"
import { LoadingButton } from "@mui/lab";

const Divider = styled(MuiDivider)(spacing);


function SignatureConfigNotification() {


let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();

const [suspend_after_count, setSuspendAfterCount] = React.useState(1);
const [suspend_after_count_type, setSuspendAfterCountType] = React.useState(3);


const [cancel_after_count, setCancelAfterCount] = React.useState(2);
const [cancel_after_count_type, setCancelAfterCountType] = React.useState(3);


const [sendEmailAfterCancelToCommercial, setSendEmailAfterCancelToCommercial] = React.useState(true);
const [sendEmailAfterSuspendToCommercial, setSendEmailAfterSuspendToCommercial] = React.useState(true);



  return (
    <React.Fragment>
      <Typography variant="h6" fontFamily="sans-serif"  gutterBottom display="inline" >
        Crie comandos de acordo com a situação das assinaturas
      </Typography>
      <Divider my={2} />
      

    <Typography gutterBottom display="inline">
    <Checkbox value={sendEmailAfterSuspendToCommercial} 
    onClick={e=> setSendEmailAfterSuspendToCommercial(!sendEmailAfterSuspendToCommercial)} />
      Enviar e-mail para o comercial após o contrato ser suspensa.
    </Typography>

    
    <Divider my={2} />
    <Typography gutterBottom display="inline">
    <Checkbox value={sendEmailAfterCancelToCommercial}
    onClick={e=> setSendEmailAfterCancelToCommercial(!sendEmailAfterCancelToCommercial)} />
      Enviar e-mail para o comercial após o contrato ser cancelada.
    </Typography>
   


     <Divider my={2} />
    <Typography gutterBottom display="inline">
    <Checkbox defaultChecked />
      Enviar e-mail para <TextField id="outlined-basic" size="small" label="e-mail adicional" variant="outlined" /> após o contrato ser cancelada.
    </Typography>

    
    <Divider my={2} />

    <Typography gutterBottom display="inline">
    <Checkbox defaultChecked />
      Enviar e-mail para <TextField id="outlined-basic" size="small" label="e-mail adicional" variant="outlined" /> após o contrato ser suspenso.
    </Typography>
       
       
    </React.Fragment>
  );
}

export default SignatureConfigNotification;
