import React, { useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
} from "react-feather";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  CircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid as MuiGrid,
  InputLabel,
  LinearProgress as MuiLinearProgress,
  Link,
  ListSubheader,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography as MuiTypography,
} from "@mui/material";
import { fontSize, spacing } from "@mui/system";
import { useEffect } from "react";
import useAuth from "../../../hooks/useAuth";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);





function Profile() {

  let { getApi,postApi } = useAuth();

const [loading, setLoading] = useState(false);

const Typography = styled(MuiTypography)(spacing);

const [listClients, setListClients] = React.useState([]);

const FontFamily='-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

useEffect(() => {
  LoadClientes();
},[]);


const LoadClientes = async () => {
    
  setLoading(true);
  let response = await postApi("/costumer/list/querycomplete",
  {
    "querycomplete":true,
    "limit":10000,
    "page":0,
    "queryString":{
      "costumer":{}
    },

  });
  
  setListClients(response.lista.map(x=> x.costumer));

  setLoading(false);

};
const [allowListSelected, setAllowListSelected] = React.useState([]);
const [blockListSelected, setBlockListSelected] = React.useState([]);


const handleChangeMultipleAllowList = (event) => {
  const { options } = event.target;
  const value: string[] = [];
  for (let i = 0, l = options.length; i < l; i += 1) {
    if (options[i].selected) {
      value.push(options[i].value);
    }
  }

  setAllowListSelected(value);
};

const handleChangeMultipleBlockList = (event) => {
  const { options } = event.target;
  const value: string[] = [];
  for (let i = 0, l = options.length; i < l; i += 1) {
    if (options[i].selected) {
      value.push(options[i].value);
    }
  }

  setBlockListSelected(value);
};


const UpdatePermissionStatus = async (allow) => {

  setLoading(true);
  let response = await postApi("/allstore/update/status/users",
  {
    costumer_list:allow?blockListSelected:allowListSelected,
    allow:allow

  });

  LoadClientes();
};



  return (
    <React.Fragment>
      <Helmet title="Portal do Cliente" />

      <Typography variant="h4" gutterBottom display="inline">
      Atribuir Acesso
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="#">
        Portal do Cliente
        </Link>
        <Typography>Atribuir Acesso</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {loading &&
         
         <Grid container justifyContent="center" >
         <Grid item>
         <Box sx={{ display: 'flex' }}>
            <CircularProgress 
        alignItems="center"
        justifyContent="center" color="secondary" />
         </Box>
         </Grid>
       </Grid>


       }
    {true==true &&
    
 

      <Grid 
      direction="row"
      justifyContent="center"
     sx={{ marginTop:5, width:"100%", alignContent:"center"}} container >
        <Grid item mr={15}>
        <FormControl sx={{  width: 400}}>
        <Typography fontSize={15}>
        Acesso permitido
        </Typography>
        <Select
          multiple
          native
          value={allowListSelected}
          // @ts-ignore Typings are not considering `native`
          onChange={handleChangeMultipleAllowList}
          label="Native"
          inputProps={{
            id: 'select-multiple-native',
            style:{
              'height':350
            }
          }}
          input={
            <OutlinedInput sx={{fontSize: '1rem'}} label="Tag" />
          }
        >
          {listClients.map((client) => (
            client["status"]["id"]==1 &&
            <option key={client.id} value={client.id}>
              {client.fullname+" - "+client.tax_identifier}
            </option>
          ))}
        </Select>
      </FormControl>
        
        </Grid>

        <Grid item mt={35} >
        <Grid item>
        <Button disabled={loading} variant="contained" color="secondary" onClick={e=> UpdatePermissionStatus(true)}> 
            <KeyboardDoubleArrowLeftIcon />
        </Button>
        </Grid>
        <Grid item mt={5}>
        <Button disabled={loading} variant="contained" color="secondary" onClick={e=> UpdatePermissionStatus(false)}>
            <KeyboardDoubleArrowRightIcon />
        </Button>
        </Grid>
        
        </Grid>

        <Grid item ml={15}>
        <FormControl sx={{  width: 400}}>
        <Typography fontSize={15}>
        Acesso bloqueado
        </Typography>
        <Select
          multiple
          native
          value={blockListSelected}
          // @ts-ignore Typings are not considering `native`
          onChange={handleChangeMultipleBlockList}
          label="Native"
          inputProps={{
            id: 'select-multiple-native',
            style:{
              'height':350
            }
          }}
          input={
            <OutlinedInput sx={{fontSize: '1rem'}} label="Tag" />
          }
        >
         {listClients.map((client) => (
            client["status"]["id"]!=1 &&
            <option key={client.id} value={client.id}>
              {client.fullname+" - "+client.tax_identifier}
            </option>
          ))}
        </Select>
      </FormControl>
        </Grid>
      </Grid>   
      }
    </React.Fragment>
  );
}

export default Profile;
